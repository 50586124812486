import * as Sentry from '@sentry/vue'
import type { Plugin } from 'vue'
import { replayIntegration } from '@sentry/vue'

const sentryVuePlugin: Plugin = {
  install: (app) => {
    Sentry.init({
      enabled: Boolean(import.meta.env.VITE_SENTRY_VUE_ENABLED),
      app,
      dsn: import.meta.env.VITE_SENTRY_VUE_DSN ?? '',
      tracesSampleRate: Number.parseFloat(
        import.meta.env.VITE_SENTRY_VUE_TRACES_SAMPLE_RATE ?? '0.0',
      ),

      logErrors: true,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? 'failed',

      replaysSessionSampleRate: Number.parseFloat(
        import.meta.env.VITE_SENTRY_VUE_REPLAYS_SESSION_SAMPLE_RATE ?? '0.0',
      ),
      replaysOnErrorSampleRate: Number.parseFloat(
        import.meta.env.VITE_SENTRY_VUE_REPLAYS_ON_ERROR_SAMPLE_RATE ?? '1.0',
      ),

      timeout: 500,
      trackComponents: true,

      tracePropagationTargets: [
        'localhost',
        // Everything that ends with .test
        /.*\.test/,
        // Our production and staging domains
        /^https:\/\/comsimple\.clickbar\.app/,
        /^https:\/\/comsimple\.com/,
      ],

      integrations: [replayIntegration(), new Sentry.BrowserTracing()],
    })
  },
}

export default sentryVuePlugin
