import type { Plugin, InjectionKey, Ref } from 'vue'
import { useColorMode } from '@vueuse/core'

export interface Theme {
  colorMode: Ref<'light' | 'dark'>
}

const injectionKey: InjectionKey<Theme> = Symbol('UploadContext')

export const themePlugin: Plugin = {
  install(app, options) {
    const colorMode = useColorMode()
    app.provide(injectionKey, {
      colorMode: colorMode.state,
    })
  },
}
export function useTheme() {
  return inject(injectionKey)!
}
