import Numbro from 'numbro'
// @ts-expect-error missing typings
import deCH from 'numbro/languages/de-CH'
// @ts-expect-error missing typings
import deDE from 'numbro/languages/de-DE'

export default {
  install: () => {
    Numbro.registerLanguage(deDE)
    Numbro.registerLanguage(deCH)
    Numbro.setLanguage(navigator.language)
  },
}
