import { computed, ref } from 'vue'

import { choose } from './pluralization'

import type { Plugin, ComputedRef } from 'vue'
import { capitalize } from '@/Utils/misc'
import { setLanguageFile as setTuiLanguageFile } from '@clickbar/tailwindui-vue'

type MessagesInterface = Record<string, string>
type ReplacementsInterface = Record<string, string | number | null | undefined>

export const definedLanguages = {
  de: 'Deutsch',
  en: 'English',
  pl: 'Polski',
  cs: 'Čeština',
  fr: 'Français',
  nl: 'Nederlands',
  uk: 'Українська',
  gsw: 'Schwiizerdütsch',
  ja: '日本語',
  ...(import.meta.env.DEV ? { empty: '' } : {}),
} satisfies Record<string, string>
const fallbackLanguage = 'en' satisfies keyof typeof definedLanguages

const isServer = typeof window === 'undefined'

/**
 * Stores the current options.
 */
const activeLanguage = ref(getDefaultLanguage())

/**
 * Stores the loaded languages.
 */
const loadedLanguageFiles = new Map<string, MessagesInterface>()

/**
 * The active messages to use.
 */
const activeMessages = ref<MessagesInterface>({})

function getDefaultLanguage(): string {
  return !isServer && document.documentElement.lang
    ? document.documentElement.lang.replace('-', '_')
    : fallbackLanguage
}

/**
 * Checks if the language is loaded.
 */
export function isLoaded(lang: string): boolean {
  lang = lang.replace('-', '_')

  return loadedLanguageFiles.has(lang)
}

/**
 * Get the translation for the given key.
 */
export function trans(
  key: string | null | undefined,
  replacements?: ReplacementsInterface,
): string {
  if (key == null || activeLanguage.value === 'empty') {
    return ''
  }

  return makeReplacements(activeMessages.value[key] ?? key, replacements)
}

/**
 * Get the translation for the given key and watch for any changes.
 */
export function wTrans(key: string, replacements?: ReplacementsInterface): ComputedRef<string> {
  return computed(() => trans(activeMessages.value[key], replacements))
}

/**
 * Translates the given message based on a count.
 */
export function transChoice(
  key: string,
  number: number,
  replacements: ReplacementsInterface = {},
): string {
  const message = trans(key, replacements)
  replacements.count = number.toString()

  return makeReplacements(choose(message, number, activeLanguage.value), replacements)
}

/**
 * Translates the given message based on a count and watch for changes.
 */
export function wTransChoice(
  key: string,
  number: number,
  replacements: ReplacementsInterface = {},
): ComputedRef<string> {
  return computed(() => transChoice(key, number, replacements))
}

async function loadLanguageFile(lang: string) {
  if (lang === 'empty') {
    return {}
  }

  const file = await import(`../../../../lang/${lang}.json`)
  return file.default as MessagesInterface
}

/**
 * Returns the current active language.
 */
export function getActiveLanguage(): string {
  return activeLanguage.value
}

export async function setLanguage(lang = activeLanguage.value) {
  if (!isServer) {
    // When setting the HTML lang attribute, hyphen must be use instead of underscore.
    document.documentElement.setAttribute('lang', lang.replace('_', '-'))
  }

  if (!loadedLanguageFiles.has(lang)) {
    loadedLanguageFiles.set(lang, await loadLanguageFile(lang))
  }

  activeLanguage.value = lang
  activeMessages.value = loadedLanguageFiles.get(lang)!

  setTuiLanguageFile(activeMessages.value)
}

/**
 * Make the place-holder replacements on a line.
 */
function makeReplacements(message: string, replacements?: ReplacementsInterface): string {
  if (!replacements) {
    return message
  }

  for (const [key, value] of Object.entries(replacements)) {
    const stringValue = value?.toString() ?? ''

    message = message
      .replace(`:${key}`, stringValue)
      .replace(`:${key.toUpperCase()}`, stringValue.toUpperCase())
      .replace(`:${capitalize(key)}`, capitalize(stringValue))
  }

  return message
}

export async function loadActiveLanguageFile() {
  const lang = activeLanguage.value
  const languageFile = await loadLanguageFile(lang)

  loadedLanguageFiles.set(lang, languageFile)
}

/**
 * The Vue Plugin. to be used on your Vue app like this: `app.use(i18nVue)`
 */
export const i18nVue: Plugin = {
  install: (app) => {
    ;(app.config.globalProperties as any).$t = (
      key: string | null | undefined,
      replacements?: ReplacementsInterface,
    ) => trans(key, replacements)
    ;(app.config.globalProperties as any).$tChoice = (
      key: string,
      number: number,
      replacements?: ReplacementsInterface,
    ) => transChoice(key, number, replacements)

    setLanguage(activeLanguage.value)
  },
}
