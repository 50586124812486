import type { Plugin } from 'vue'
import { Inertia } from '@inertiajs/inertia'

// This is a utility to count the number of Inertia events that have been fired.
// It is used for E2E testing using Laravel Dusk.
// Inspired from
const inertiaDuskVuePlugin: Plugin = {
  install: (app) => {
    if (!import.meta.env.VITE_IS_TESTING || !JSON.parse(import.meta.env.VITE_IS_TESTING)) {
      return
    }

    // @ts-expect-error this is for testing purposes only
    window.inertiaEventsCount = {
      navigateCount: 0,
      successCount: 0,
      errorCount: 0,
    }

    Inertia.on('navigate', (event) => {
      // @ts-expect-error this is for testing purposes only
      window.inertiaEventsCount.navigateCount++
    })

    Inertia.on('success', (event) => {
      // @ts-expect-error this is for testing purposes only
      window.inertiaEventsCount.successCount++
    })

    Inertia.on('error', (event) => {
      // @ts-expect-error this is for testing purposes only
      window.inertiaEventsCount.errorCount++
    })
  },
}

export default inertiaDuskVuePlugin
