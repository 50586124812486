import '@fontsource-variable/inter'

import '@clickbar/tailwindui-vue/style.css'
import '../css/shake.css'

import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { createInertiaApp, Link } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { createApp, h, watch, type DefineComponent } from 'vue'

import '../css/app.css'
import { useLanguage } from '@/Composables/spaceship'
import { loadActiveLanguageFile, i18nVue, setLanguage } from '@/Plugins/i18n'
import numbroPlugin from '@/Plugins/numbro'
import sentryVuePlugin from '@/Plugins/sentry'
import inertiaDuskPlugin from '@/Plugins/inertiaDusk'
import { themePlugin } from '@/Plugins/theme'

// @ts-expect-error-next-line
import { registerSW } from 'virtual:pwa-register'

const appName = window.document.querySelectorAll('title')[0].textContent ?? 'ComSimple'

// global watcher for changing the locale
const locale = useLanguage()
watch(locale, (locale) => {
  setLanguage(locale)
})

// eslint-disable-next-line unicorn/prefer-top-level-await
loadActiveLanguageFile().then(() => {
  createInertiaApp({
    title: (title: string) => `${title} - ${appName}`,

    resolve: (name: string) =>
      resolvePageComponent(
        `./Pages/${name}.vue`,
        import.meta.glob<DefineComponent>('./Pages/**/*.vue'),
      ),

    setup({ el, app, props, plugin }) {
      createApp({ render: () => h(app, props) })
        .use(plugin)
        .use(themePlugin)
        .use(i18nVue)
        .use(numbroPlugin)
        .use(autoAnimatePlugin)
        .use(sentryVuePlugin)
        .use(inertiaDuskPlugin)
        .component('InertiaLink', Link)
        .component('inertia-link', Link)
        .mount(el)
    },
  })

  InertiaProgress.init({ color: '#ffffff' })
})

registerSW({ immediate: true })
